body {
  box-sizing: border-box;
}



.crosby {
  height: 50vh;
  margin: 10px;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}